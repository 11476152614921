<template>
  <div class="homepage">
    <section class="section section__hero" :style="styleObject">
      <div class="flex hero__content">
        <div class="hero__content-text">
          <span class="mb-40"> {{ $store.state.district }}</span>
          <h1 class="mb-40">{{ $store.state.title }}</h1>
          <p class="mb-40">
            नागरिक सहभागितामार्फत विद्युतिय सुशासन सुनिश्चितताका लागि
            {{ $store.state.title }}का अभियान ।
          </p>
          <a
            class="flex btn btn--more"
            id="show-modal"
            @click="ModalOn"
            title="थप जानकारी"
            >थप जानकारी<i class="arrow-right"></i
          ></a>
        </div>
      </div>
    </section>
    <div class="components-wrapper mb-80">
      <section class="section mb-80 section__echarter">
        <div class="container flex">
          <div class="echarter__image">
            <img
              :src="imgEcharter"
              alt="नागरिक वडापत्र"
              width="480"
              height="380"
            />
          </div>
          <div class="echarter__content">
            <h2 class="mb-24">नागरिक वडापत्र</h2>
            <p class="mb-24">
              नागरिक बडापत्र खण्डमा तपाईले पालिका एवं वडा कार्यालयहरुबाट प्रदान
              गरिने विभिन्न प्रकारका सेवाहरु, उक्त सेवा प्राप्त गर्नका लागि
              पालना गर्नुपर्ने प्रकृया, जिम्मेवार अधिकारी एवं शुल्कलगायतका
              बारेमा जानकारी प्राप्त गर्न सक्नुहुनेछ ।
            </p>
            <a
              href="/citizen-charter"
              class="flex btn btn--more"
              title="थप जानकारी"
              >थप जानकारी<i class="arrow-right"></i
            ></a>
          </div>
        </div>
      </section>
      <section class="section mb-80 section__eprofile">
        <div class="container flex">
          <div class="eprofile__content">
            <h2 class="mb-24">वस्तुगत विवरण</h2>
            <p class="mb-24">
              यस खण्डमा तपाईले {{ $store.state.title }} काे बस्तुस्थितिसँग
              सम्बन्धित जानकारी प्राप्त गर्न सक्नुहुनेछ। यसबाहेक,
              {{ $store.state.title }} काे केही विषयगत क्षेत्रबारे जानकारी
              नक्सामा समेत हेर्न सकिनेछ।
            </p>
            <a href="/e-profile" class="flex btn btn--more" title="थप जानकारी"
              >थप जानकारी <i class="arrow-right"></i
            ></a>
          </div>
          <div class="eprofile__image">
            <img
              :src="imgEprofile"
              alt="वस्तुगत विवरण"
              width="480"
              height="380"
            />
          </div>
        </div>
      </section>
      <section class="section section__document-library">
        <div class="container flex">
          <div class="document-library__image">
            <img
              :src="imgDocumentLibrary"
              alt="कागजात संग्रह"
              width="480"
              height="380"
            />
          </div>
          <div class="document-library__content">
            <h2 class="mb-24">कागजात संग्रह</h2>
            <p class="mb-24">
              कागजात संग्रहमा कागजात संग्रह अन्तर्गत तपाईले स्थानिय सरकार तथा
              अन्यबाट प्रकाशित भएका दस्तावेजहरु हेर्न सक्नुहुन्छ । खासगरी
              तथ्यांकीय पाेर्टलमा तर विभिन्न विषयसँग सम्बन्धित उपयाेगी
              प्रतिवेदनहरु, निर्देशिका, ऐन/नियमावली/विनियम/कार्यविधि यस खण्डमा
              उपलब्ध हुनेछ । यस्ता तथ्यांकलाई आफ्नाे आवश्यकता अनुसार डाउनलाेड
              गर्न सकिन्छ।
            </p>
            <a
              href="/document-library"
              class="flex btn btn--more"
              title="वस्तुगत विवरण"
              >थप जानकारी<i class="arrow-right"></i
            ></a>
          </div>
        </div>
      </section>
    </div>
    <section class="section mb-80 section__information">
      <div class="container flex">
        <div class="information__image">
          <img
            :src="imgInformation"
            alt="नीति,निर्णय,कार्यक्रम"
            width="455"
            height="452"
          />
        </div>
        <div class="information__content">
          <div class="mb-40 flex information--policies">
            <i class="flex information-icon information--policies-icon"></i>
            <div>
              <h3 class="mb-8">वार्षिक नीति</h3>
              <p class="mb-8">
                यस खण्डमा {{ $store.state.title }}ले विभिन्न वर्ग एवं विषयगत
                क्षेत्रहरुका लागि अवलम्बन गरेका वार्षिक नीतिहरु हेर्न सकिन्छ।
                यसमा रहेका जानकारी आवश्यकताअनुसार डाउनलाेड गरी प्रयाेग समेत गर्न
                सकिनेछ।
              </p>
              <a href="/policies" class="flex more" title="थप जानकारी"
                >थप जानकारी <i class="arrow-right"></i
              ></a>
            </div>
          </div>
          <div class="mb-40 flex information--decisions">
            <i class="flex information-icon information--decisions-icon"></i>
            <div>
              <h3 class="mb-8">वार्षिक निर्णय</h3>
              <p class="mb-8">
                यस खण्डमा {{ $store.state.title }}काे वार्षिक सभाले विभिन्न
                विषयमा गरेका निर्णयहरु हेर्न सकिन्छ। यसमा रहेका जानकारी
                आवश्यकताअनुसार डाउनलाेड गरी प्रयाेग समेत गर्न सकिनेछ।
              </p>
              <a href="/decisions" class="flex more" title="थप जानकारी"
                >थप जानकारी <i class="arrow-right"></i
              ></a>
            </div>
          </div>
          <div class="flex information--budget">
            <i class="flex information-icon information--budget-icon"></i>
            <div>
              <h3 class="mb-8">वार्षिक कार्यक्रम</h3>
              <p class="mb-8">
                यस खण्डमा {{ $store.state.title }}काे वार्षिक कार्यक्रमहरु हेर्न
                सकिन्छ। यस {{ $store.state.title }}ले चालु तथा पुँजीगत गरी के
                कस्ता याेजना तथा कार्यक्रम तय गरी बजेट विनियाेजन गरेकाे छ भनी
                हेर्न सकिन्छ। उक्त जानकारी आवश्यकताअनुसार डाउनलाेड गरी प्रयाेग
                समेत गर्न सकिनेछ।
              </p>
              <a href="/budget" class="flex more" title="थप जानकारी"
                >थप जानकारी <i class="arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section mb-80 section__health">
      <div class="container">
        <SectorStats
          :decisionCount="getDecisionCount"
          :policyCount="getPolicyCount"
          :totalBudget="getBudgetCount"
          :sectorType="type"
        />
        <h2 class="mb-24">
          {{
            $store.state.sectors[this.type]
              ? $store.state.sectors[this.type].name
              : ""
          }}
          क्षेत्र
        </h2>
        <p class="mb-40">
          {{
            $store.state.sectors[this.type]
              ? $store.state.sectors[this.type].shortText
              : ""
          }}
        </p>
        <router-link
          :to="`/sectors/${type}`"
          class="flex btn btn--more"
          title="थप जानकारी"
          >थप जानकारी<i class="arrow-right"></i>
        </router-link>
      </div>
    </section>
    <section class="section mb-80 section__partners">
      <div class="container">
        <h2 class="mb-24">साझेदारी संस्थाहरु</h2>
        <ul class="flex">
          <li class="eu">
            <a href="" target="_blank" title="European Union"
              ><span>Funded by</span
              ><img
                src="../../assets/img_partner-1.png"
                alt="European Union"
                width=""
                height=""
              />
              <span>European Union</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.sahakarmisamaj.org/"
              target="_blank"
              title="Sahakarmi Samaj"
              ><img
                src="../../assets/img_partner-2.png"
                alt=""
                width="Sahakarmi Samaj"
                height=""
            /></a>
          </li>
          <li class="dds">
            <a href="" target="_blank" title="Dalit Development Society"
              ><img
                src="../../assets/img_partner-3.png"
                alt="Dalit Development Society"
                width=""
                height=""
            /></a>
          </li>
          <li>
            <a
              href="http://centerfordalitwomen.org.np/"
              target="_blank"
              title="Center for Dalit Women Nepal"
              ><img
                src="../../assets/img_partner-4.png"
                alt="Center for Dalit Women Nepal"
                width=""
                height=""
            /></a>
          </li>
          <li>
            <a href="https://www.imsweden.org/en/" target="_blank" title="IM"
              ><img
                src="../../assets/img_partner-5.png"
                alt="IM"
                width=""
                height=""
            /></a>
          </li>
          <li>
            <a
              href="https://www.tdh.ch/en"
              target="_blank"
              title="Terre des hommes"
              ><img
                src="../../assets/img_partner-6.png"
                alt="Terre des hommes"
                width=""
                height=""
            /></a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import imgEcharter from "../../assets/img_echarter.jpg";
import imgEprofile from "../../assets/img_eprofile.svg";
import imgDocumentLibrary from "../../assets/img_document-library.jpg";
import imgInformation from "../../assets/img_information.jpg";
import { englishToNepaliNumber } from "nepali-number";
import LocalGovRepository from "./../../repositories/LocalGovernmentRepository";
import BudgetRepository from "./../../repositories/Budget";
import municipalityRepository from "../../repositories/MunicipalityRepository";
// import HeroImg from "../../assets/img_hero.jpg";
const SectorStats = defineAsyncComponent(() =>
  import("../../components/sector/sectorStats.vue")
);

export default {
  components: {
    SectorStats,
  },
  data: function () {
    return {
      imgEcharter: imgEcharter,
      imgEprofile: imgEprofile,
      imgDocumentLibrary: imgDocumentLibrary,
      imgInformation: imgInformation,
      policies: [],
      decisions: [],
      budgets: [],
      details: [],
      type: "",
      lgName: process.env.VUE_APP_PAGE_TITLE,
    };
  },
  mounted() {
    this.$store.dispatch("setLoading", false);
    switch (process.env.VUE_APP_LOCAL_GOV) {
      case "bheri":
        this.type = "education";
        break;
      case "triveni":
        this.type = "water_sanitation";
        break;
      case "kapurkot":
        this.type = "agriculture";
        break;
      case "khadachakra":
        this.type = "health";
        break;
    }

    this.getPolicies();
    this.getDecisions();
    this.getBudgets();
    this.getMunicipalityDetails();
  },
  methods: {
    ModalOn() {
      this.$store.dispatch("setModal", true);
      this.$store.dispatch("setModalData", {
        header: "",
        body: `यसमार्फत सम्पूर्ण नागरिक, संघसंस्था एवं अन्य सराेकारवालाहरुले ${this.$store.state.title}का सार्वजनिक सूचना जानकारी एवं आँकडा सर्वसुलभ रुपमा प्राप्त गर्न सक्नुहुनेछ। ${this.$store.state.title} एक खुला स्थानीय तहका रुपमा आफूलाई स्थापित गर्नका लागि प्रयत्नरत छ। यसमार्फत सम्पूर्ण नागरिक, संघसंस्था एवं अन्य सराेकारवालाहरुले ${this.$store.state.title}का सार्वजनिक सूचना जानकारी एवं आँकडा सर्वसुलभ रुपमा प्राप्त गर्न सक्नुहुनेछ। ${this.$store.state.title} एक खुला स्थानीय तहका रुपमा आफूलाई स्थापित गर्नका लागि प्रयत्नरत छ।`,
      });
    },
    async getPolicies() {
      let params = `?sector=${this.$store.state.sectors[this.type].name}`;
      let { data } = await LocalGovRepository.getPolicies(params);

      if (data.data) {
        this.policies = data.data;
      }
    },
    async getDecisions() {
      let params = `?sector=${this.$store.state.sectors[this.type].name}`;
      let { data } = await LocalGovRepository.getDecisions(params);

      if (data.data) {
        this.decisions = data.data;
      }
    },
    async getBudgets() {
      let { data } = await BudgetRepository.getBudget();

      if (data.data) {
        this.budgets = data.data;
      }
    },
    async getMunicipalityDetails() {
      try {
        let { data } = await municipalityRepository.getDetails();
        this.details = data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    getDecisionCount() {
      let decisionCount = 0;

      for (const i in this.decisions) {
        decisionCount += this.decisions[i].decision_listings.length;
      }

      return englishToNepaliNumber(decisionCount);
    },
    getPolicyCount() {
      let policyCount = 0;

      for (const i in this.policies) {
        for (const j in this.policies[i].policies) {
          policyCount += this.policies[i].policies[j].descriptions.length;
        }
      }

      return englishToNepaliNumber(policyCount);
    },
    getBudgetCount() {
      let totalBuget = 0;

      for (const i in this.budgets) {
        if (
          this.budgets[i].sector_json &&
          this.budgets[i].sector_json[this.$store.state.sectors[this.type].name]
        ) {
          totalBuget +=
            this.budgets[i].sector_json[
              this.$store.state.sectors[this.type].name
            ];
        }
      }

      return englishToNepaliNumber(totalBuget);
    },
    styleObject() {
      if (this.details.assets) {
        return {
          background:
            "url(" +
            this.details.assets.background_image +
            ")no-repeat center / cover",
        };
      } else {
        return {
          background: "#fff",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.homepage {
  background: $neutrals-white;
}

.section__hero {
  height: calc(100vh - 80px);
  position: relative;
  @media all and (max-width: 600px) {
    height: auto;
  }
  &:before {
    @include dots;
    left: -48px;
    top: 80px;
    @media all and (max-width: 600px) {
      display: none;
    }
  }
}

.hero__content {
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  height: 100%;
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 80px 0;
  }
  &-text {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 20px;
    position: relative;
    @media all and (max-width: 600px) {
      max-width: 420px;
    }
    span {
      display: block;
    }
    p {
      font-size: 18px;
    }
  }
}

.components-wrapper {
  @include bg-primary-gradient;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  color: $neutrals-white;
  padding: 120px 20px;
  @media all and (max-width: 768px) {
    padding: 40px 20px;
  }
  .container.flex {
    align-items: center;
  }
  img {
    border-radius: 24px;
    object-fit: cover;
    @media all and (max-width: 768px) {
      height: auto;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  > section > .container.flex {
    @media all and (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.section__echarter {
  overflow: hidden;
  position: relative;
  &:before {
    @include dots;
    bottom: 120px;
    left: -24px;
    filter: brightness(0) invert(1);
    opacity: 0.5;
    @media all and (max-width: 600px) {
      display: none;
    }
  }
}

.section__eprofile {
  overflow: hidden;
  position: relative;
  &:after {
    @include dots;
    right: -48px;
    top: 60px;
    @media all and (max-width: 600px) {
      display: none;
    }
  }
  > .container.flex {
    @media all and (max-width: 768px) {
      flex-direction: column-reverse !important;
    }
  }
}

.eprofile__content,
.echarter__content,
.document-library__content,
.components-wrapper img {
  max-width: 380px;
  @media all and (max-width: 768px) {
    max-width: 420px;
  }
}

.echarter__content,
.document-library__content {
  @media all and (min-width: 768px) {
    padding-left: 24px;
  }
}

.eprofile__content {
  @media all and (min-width: 768px) {
    padding-right: 24px;
  }
}

.eprofile__image img {
  @media all and (max-width: 768px) {
    min-height: 240px;
  }
}

.section__information {
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  &:after {
    @include dots;
    right: -48px;
    top: 30%;
    @media all and (max-width: 600px) {
      display: none;
    }
  }
  @media all and (max-width: 768px) {
    .container {
      display: block;
      max-width: 420px;
      padding: 0 20px;
      img {
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }
}

.information {
  &__content {
    max-width: 440px;
    > div {
      position: relative;
    }
    p {
      color: rgba($neutrals-black, 0.5);
      font-weight: 500;
    }
  }
  &__image {
    position: relative;
    &:before {
      @include dots;
      left: -77px;
      top: 30%;
    }
    img {
      border-radius: 24px;
      height: 100%;
      object-fit: cover;
      position: relative;
      vertical-align: middle;
    }
  }
  &-icon {
    @include bg-neutral-gradient;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    height: 68px;
    width: 68px;
    &:before {
      background: url(../../assets/ic_sprite.svg) no-repeat 0 0;
      content: "";
      display: block;
      height: 20px;
      width: 20px;
    }
    & + div {
      width: calc(100% - 68px);
      margin-left: 16px;
    }
  }
  &--policies-icon:before {
    background-position: 0 -46px;
  }
  &--decisions-icon:before {
    background-position: 0 -88px;
  }
  &--budget-icon:before {
    background-position: 0 -128px;
  }
}

.section__health {
  .container {
    border-radius: 24px;
    @include bg-primary-gradient;
    color: $neutrals-white;
    padding: 40px;
    text-align: center;
    @media all and (max-width: 600px) {
      padding: 40px 20px;
    }
    h2:after {
      margin: 12px auto;
    }
  }
  .btn--more {
    margin: 0 auto;
  }
}

.section__partners {
  padding: 0 20px;
  text-align: center;
  h2:after {
    background-color: $neutrals-black;
    margin-left: auto;
    margin-right: auto;
  }
  ul {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 20px;
      &.eu {
        margin-top: -20px;
      }
      &.eu,
      &.dds {
        pointer-events: none;
      }
      height: 102px;
      margin-right: 12px;
      img {
        height: 90px;
        object-fit: contain;
        vertical-align: middle;
        width: auto;
      }
      > a > span {
        color: $neutrals-black;
        display: block;
        font-size: 10px;
        font-weight: 600;
      }
      &:last-child {
        img {
          width: 250px;
        }
      }
    }
    @media all and (max-width: 768px) {
      padding: 0 20px;
      li {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
